@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,500;1,500&display=swap');

* {
    box-sizing: border-box;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}


/*  //////////////////////////////
    //                          //
    //         Contact          //  
    //                          //
    ////////////////////////////// */

#contact {
    width: 100vw;
    padding: 2vh 2vh 2vh 0;
    padding-bottom: 7.5vh;
    border-radius: 10px 10px 0 0;
    text-align: center;
    margin-top: 10vh;
}

#contactlinks {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 5vw;
    row-gap: 2vh;
}

.contactlink {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 6.5vh;
    min-width: 3vh;
    text-decoration: inherit;
    color: inherit;
    font-size: smaller;
    text-align: center;
}

.contactlink>img {
    width: 100%;
    height: 100%;
    margin-bottom: 1vh;
}




/*  //////////////////////////////
    //                          //
    //         Cards            //  
    //                          //
    ////////////////////////////// */


.cardsholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card {
    margin-left: 2vh;
    margin-right: 2vh;
    margin-bottom: 2vh;
    align-items: center;

    max-width: 40vh;
    min-width: 30vh !important;

    transition: transform .2s;
}

.card:hover {
    transform: scale(1.075);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}





/*  //////////////////////////////
    //                          //
    //         Button           //  
    //                          //
    ////////////////////////////// */

:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: none;
}

.btn--primary {
    background-color: var(--primary);
    color: #CDB4DB;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 4px 14px;
    font-size: 16px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 26px;
}

.btn--small:hover,
.btn--large:hover,
.btn--large:hover {
    background: #fff;
    color: #242424;
    transition: 50ms;
}





/*  //////////////////////////////
    //                          //
    //         Navbar           //  
    //                          //
    ////////////////////////////// */

.navbar,
.nav-item,
.nav-menu,
.navbar-container,
.nav-links,
.navbar-logo,
.logo {
    background-color: #e096b1;
    /* height: 80px; */
}

.navbar {
    position: sticky !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    padding-top: 1vh;
    justify-content: center;
    align-items: center;
}

.navbar-logo {
    width: auto;
    height: auto;
    justify-self: start;
    margin-right: 50px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.logo {
    height: 75px;
    width: 75px;
}

.whiteIcon {
    color: #fff;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.rightimage {
    height: 100%;
    transition: height 0.1s ease-in-out;
    width: 100%;
}

.nav-menu {
    display: flex;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    justify-content: right;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}










/*  //////////////////////////////
    //                          //
    //     Video Container      //  
    //                          //
    ////////////////////////////// */


video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.video-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    z-index: 1;
}

.video-container>h1 {
    color: #ffffff;
    text-align: left;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 100px;
    margin-top: -100px;
}

.video-container>span {
    color: #444;
    text-align: left;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 100px;
    margin-top: -100px;
}


.animatedText {
    color: #ffffff;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 25px;
}

.Typewriter__cursor {
    /* Keyword values */
    visibility: visible !important; 
    visibility: hidden !important;
    visibility: collapse !important;
}

.video-container>p {
    margin-top: 8px;
    /* color: #fff; */
    color: #444;
    text-align: left;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 100px;
    margin-top: -100px;
    font-size: 32px;
    background-color: transparent;
    box-shadow: #000000;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .animatedText {
        text-align: center;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}





/*  //////////////////////////////
    //                          //
    //          Text            //  
    //                          //
    ////////////////////////////// */



.text {
    color: #444;
    text-align: left;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 25px;
    font-weight: 300;
    line-height: 25px;
}

.whiteText {
    color: #FFF;
    text-align: left;
    letter-spacing: 2px;
    margin-top: 0;
    font-family: Inconsolata, monospace;
    font-size: 25px;
    font-weight: 300;
    line-height: 35px;
}





/*  //////////////////////////////
    //                          //
    //       Introduction       //  
    //                          //
    ////////////////////////////// */


.intro {
    background-image: linear-gradient(#fff, #fff);
}

.sideimg {
    width: 10vh;
    margin-right: 10vh;
    float: left;
}

.lefttext {
    text-align: right;
    float: left;
    max-height: 100%;
}

.medialeft{
    display: flex;
    align-items: center;
    margin-left: 9vh;
    margin-right: 9vh;  
    width: 100%;
}

.mediaright {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.SpotifyPlayer {
    display: inherit;
    width: 75%;
    height: 75%;
    position: inherit;
    margin-left: 10vh;

}

.YoutubeVideo {
    padding-top: 15vh;
    height: 75vh;
    width: 75vh;
}

.textvid {
    width: 50%;
    height: 65vh;
    min-height: 400px;
    align-self: flex-start;
    backdrop-filter: blur(20px);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.backdrop-blur {
    height: 50vh;
    width: 100%;
    backdrop-filter: blur(20px);
    margin: 0%;
    padding: 0%;
    position: absolute;
}

.text-video-container {
    height: 65vh;
    min-height: 400px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
    z-index: 1;
}

.Blur {
    height: 100%;
    width: 100%;
}

.mediaContainer{
    background-color: #333745;
    min-height: 75vh;
    display: flex;
}

.container-intro {
    justify-content: flex-end;
    align-items: center;
    padding: 200px 300px 200px 100px;
    display: block;
    position: relative;
}

.container-portfolio {
    justify-content: flex-end;
    align-items: center;
    padding: 200px 100px 100px 100px;
}

.w-container {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}

.w-heart-container {
    display: flex;
    min-width: 50%;
}


.w-about-container {
    max-width: 940px;
    margin-left: 45%;
    margin-right: auto;
    padding: 5vh 5vh 200px 200px;
    display: flex;
}

.modaldialouge {
    width: 80vh;
}

.modal-body {
    display: flex;
    justify-content: center;
}

.aboutgif {
    float: right;
    position: static;
    top: 50px;
    height: 50vh;
    width: 50vh;
    bottom: 0;
    right: 0;
    transition: height 0.3s ease-in;
    transition: width 0.3s ease-in;
}

.timeline{
    width: 100%;
    height: 100%;
    text-align: center;
}

.timeline-text{
    text-align: center;
    padding-bottom: 5vh;
}

.timeline-text-domin{
    text-align: center;
    padding-bottom:  5vh;
    padding-top: 5vh;
    font-weight: bold;
}

.w-container-timeline{
    width: 100%;
}

.abouttext {
    display: flow-root;
    min-width: 250px;
    max-width: 350px;
    max-height: 100%;
    align-items: center;
    color: #FFF;
    text-align: left;
    letter-spacing: 2px;
    font-family: Inconsolata, monospace;
    font-size: 25px;
    font-weight: 300;
    /* line-height: 35px; */
}

.aboutfont{
    color: #fff !important;
}

.about {
    display: flex;
    background-color: #333745;
}




@media screen and (max-height: 1400px) and (orientation : landscape) and (max-width : 1300) {
    .text-video-container {
        height: 65vh;
    }

    .w-about-container{
        padding: 5vh 2.5vh 200px 200px;
    }

    .textvid {
        height: 65vh;
    }
}

@media screen and (max-height: 700px) and (orientation : landscape) {

    .text-video-container{
        height: 65vh;
        transition: height 0.1s ease-in-out;
    }

    .textvid {
        height: 65vh;
        transition: height 0.1s ease-in-out;
    }

}

@media screen and (max-width: 1100px) and (orientation : landscape) {

    .whiteText {
        height: 90%;
        width: 45vh;
        transition: height 0.1s ease-in-out;
    }
}

@media screen and (max-width: 1250px) {
    .aboutgif {
        transition: height 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;
        height: 0%;
        width: 0%;
        position: sticky;
        float: left;
        top: 0px;
    }

    .abouttext{
        transition: font-size 0.3s ease-in-out;
    }
}

@media screen and (max-width : 750px) {

    .navbar,
    .nav-item,
    .nav-menu,
    .navbar-container,
    .nav-links,
    .navbar-logo,
    .logo {
        /* Keyword values */
        visibility: visible !important;
        visibility: hidden !important;
        visibility: collapse !important;
        height: 0px;
        width: 0px;
    }
}

@media screen and (max-width : 925px){
    
    .YoutubeVideo {
        transition: height 0.1s ease-in-out;
        transition: width 0.1s ease-in-out;
        height: 0%;
        width: 0%;
        position: sticky;
        float: left;
        top: 0px;
    }

    .mediaright {
        transition: height 0.5s ease-in-out;
        transition: width 0.5s ease-in-out;
        height: 0%;
        width: 0%;
        position: sticky;
        float: left;
        top: 0px;
    }
}

@media screen and (max-width: 675px) {
    .textvid{
        transition: width 0.1s ease-in-out;
        width: 40%;
        height: 65vh;
    }
        .text-video-container{
        height: 65vh;
        transition: height 0.1s ease-in-out;
    }
}

@media screen and (max-width: 550px) {
    .textvid {
        transition: width 0.1s ease-in-out;
        width: 30%;
        height: 65vh;
    }

    .text-video-container {
        height: 65vh;
        transition: height 0.1s ease-in-out;
    }
}


@media screen and (max-width: 450px) {
    .textvid {
        transition: width 0.1s ease-in-out;
        width: 0%;
        height: 65vh;
    }

    .text-video-container {
    height: 65vh;
    transition: height 0.1s ease-in-out;
    }

    .w-about-container {
        margin-left: auto;
    }

    .abouttext{
        min-width: 40vh;
        text-align: center !important;
    }

    .whiteText {
        text-align: center !important;
    }
}

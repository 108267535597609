* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inconsolata, monospace;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}